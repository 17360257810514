import classNames from 'classnames';

import { CommercialVehicleOption } from '@assured/step-renderer/types/step-components/SelectOrCreate';
import { CommercialAutoVehicleThumbnail } from '@assured/ui/components/Vehicles/CommercialAutoVehicleThumbnail';

export const CommercialVehicleEntry = ({
  entry,
  selected,
  onClick,
}: {
  entry: CommercialVehicleOption;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={classNames(
        'flex text-left items-center border rounded-lg px-4 py-2 cursor-pointer',
        selected
          ? 'border-blue-300 bg-blue-100'
          : 'border-cool-gray-300  hover:bg-cool-gray-50',
      )}
      onClick={onClick}
    >
      <div className="flex-1">
        <div className="text-sm text-cool-gray-800">
          {entry.year} {entry.make} {entry.model}{' '}
        </div>
        <div className="font-mono text-xs text-cool-gray-500">{entry.vin}</div>
      </div>
      <div className="flex flex-col items-center w-[30%] gap-1">
        <CommercialAutoVehicleThumbnail
          type={entry.commercialAutoVehicleThumbnailType}
        />
        {entry.licensePlate ? (
          <div
            className={classNames(
              'px-2 py-1 w-24 rounded border text-center text-xs shadow',
              selected
                ? 'border-blue-600 bg-blue-500'
                : 'border-cool-gray-300 bg-white',
            )}
          >
            <div
              className={classNames(
                'text-xxs leading-none',
                selected ? 'text-gray-50' : 'text-cool-gray-600',
              )}
            >
              {entry.licensePlateStateCode}
            </div>
            <div
              className={classNames(
                'font-bold leading-none',
                selected ? 'text-white' : 'text-cool-gray-800',
              )}
            >
              {entry.licensePlate}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
