import React from 'react';

import {
  SelectStateDropdown as DesignSystemSelectStateDropdown,
  SelectStateDropdownProps as DesignSystemSelectStateDropdownProps,
} from '@assured/design-system/src/components/SelectStateDropdown';

/*

This is a wrapper around the component with the same name in
the design-system package.  The purpose of this component is to
provide a single place to apply the classNames, styles, and any
future overrides of the design-system component's defaults,
for use within ClaimWorkflow.

*/

export const SelectStateDropdown: React.FC<
  DesignSystemSelectStateDropdownProps
> = ({ classNames, styles, ...props }) => {
  return (
    <DesignSystemSelectStateDropdown
      classNames={
        classNames ?? {
          container: () => 'Select text-left',
          control: () => 'Select-control',
          input: () => 'Select-input',
          placeholder: () => 'Select-placeholder',
        }
      }
      styles={
        styles ?? {
          input: base => ({
            ...base,
            marginLeft: -8,
            marginTop: 0,
            marginBottom: 0,
          }),
          valueContainer: base => ({
            ...base,
            padding: '0 8px',
          }),
          singleValue: base => ({
            ...base,
            marginLeft: 8,
          }),
          dropdownIndicator: base => ({
            ...base,
            padding: 0,
          }),
          placeholder: () => ({
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            paddingRight: '0 !important',
          }),
        }
      }
      // this is a special case component that has the exact signature of the component it wraps
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default SelectStateDropdown;
