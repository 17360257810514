import classNames from 'classnames';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';

import { CommercialAutoVehicleTypeDisplay } from '@assured/shared-types/Claim/Vehicle/InvolvedParty';
import { SearchIcon } from '@heroicons/react/solid';

import { CommercialDriverEntry } from './CommercialDriverEntry';
import { CommercialVehicleEntry } from './CommercialVehicleEntry';

import type {
  CommercialDriverOption,
  CommercialVehicleOption,
} from '@assured/step-renderer/types/step-components/SelectOrCreate';
import type {
  SelectOrCreateStepComponentSpec,
  StepComponentSharedProps,
} from '@assured/step-renderer';

const SHORT_LIST_THRESHOLD = 10;

// Known option types
type ExtendedOption = CommercialDriverOption | CommercialVehicleOption;

interface ExtendedSelectOrCreateProps
  extends StepComponentSharedProps<
    SelectOrCreateStepComponentSpec,
    ExtendedOption | { [k: string]: unknown }
  > {}

const ExtendedSelectOrCreate: React.FC<ExtendedSelectOrCreateProps> = ({
  step_component,
  updateValue,
  className,
  primaryValue,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const selectedOption = step_component.options.find(option => {
    return step_component.create.fields.every(f => {
      return (
        option?.[f.key] === primaryValue?.[f.key as keyof typeof primaryValue]
      );
    });
  });

  const fuse = useMemo(() => {
    const baseOptions =
      step_component.extended_mode_format === 'commercial_vehicle'
        ? (step_component.options as unknown as CommercialVehicleOption[])
        : (step_component.options as unknown as CommercialDriverOption[]);

    return new Fuse<ExtendedOption>(
      baseOptions.map(option => {
        const vehicleOption = option as CommercialVehicleOption;
        if (vehicleOption.commercialAutoVehicleType) {
          return {
            ...option,
            unmaskedPortionOfVIN: (vehicleOption.vin || '').replace(/\*/g, ''),
            commercialAutoVehicleTypeDisplay:
              CommercialAutoVehicleTypeDisplay[
                vehicleOption.commercialAutoVehicleType as keyof typeof CommercialAutoVehicleTypeDisplay
              ] ?? vehicleOption.commercialAutoVehicleType,
            ymm: [vehicleOption.year, vehicleOption.make, vehicleOption.model]
              .filter(Boolean)
              .join(' '),
          } as CommercialVehicleOption & {
            // Additional fields for searching
            commercialAutoVehicleTypeDisplay: string;
            ymm: string;
            unmaskedPortionOfVIN: string;
          };
        }

        return option as CommercialDriverOption;
      }),
      {
        keys: [
          // Driver fields
          'contactId',
          'employeeId',
          'firstName',
          'genderCode',
          'isExcludedDriver',
          'isPrimaryContact',
          'lastName',
          'middleInitial',
          'name',
          'suffix',

          // Vehicle fields
          'make',
          'model',
          'year',
          'unmaskedPortionOfVIN',
          'licensePlateStateCode',
          'licensePlate',
          'commercialAutoVehicleTypeDisplay', // Added in the map function above
          'ymm', // Added in the map function above
        ],
        includeScore: true,
        minMatchCharLength: 1,
        threshold: 0.4,
      },
    );
  }, [step_component.options, step_component.extended_mode_format]);

  const searchResults = useMemo(() => {
    const query = searchTerm.trim();
    if (!query) return step_component.options.slice(0, 5);
    const isLikelyVINSearch = query.length > 11 && !query.trim().includes(' ');
    const matches = fuse.search(
      isLikelyVINSearch
        ? // VINs are masked on the server and only include the last 5 characters
          // As a result we only query the last 5 characters for VIN searches
          query.slice(-5)
        : query,
    );
    const matchedOptions = matches.map(match => match.item);
    return matchedOptions.slice(0, 5);
  }, [fuse, step_component.options, searchTerm]);

  return (
    <div className={classNames(className, 'mt-4')}>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="block pl-10 py-1.5 px-4 border-cool-gray-300 border-solid border-2 rounded-lg w-full focus:outline-none focus:shadow-outline-blue text-sm leading-6"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder={
              // eslint-disable-next-line no-nested-ternary
              step_component.extended_mode_format === 'commercial_vehicle'
                ? `Search by make/model, VIN...`
                : step_component.extended_mode_format === 'commercial_driver'
                ? `Search by name${
                    step_component.options.some(o => o.employeeId)
                      ? ', employee ID, license'
                      : ''
                  }...`
                : `Search by anything...`
            }
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4">
        {/* eslint-disable-next-line no-nested-ternary */}
        {searchTerm.length === 0 &&
        step_component.options.length >= SHORT_LIST_THRESHOLD ? (
          <div className="text-cool-gray-500 text-sm font-medium">
            Type to search {step_component.options.length}{' '}
            {step_component.extended_mode_format === 'commercial_vehicle'
              ? 'vehicles on policy'
              : 'drivers on policy'}
            ...
          </div>
        ) : searchResults.length === 0 ? (
          <div className="text-cool-gray-500 text-sm font-medium">
            No results found.
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {searchResults.map(result => {
              if (
                step_component.extended_mode_format === 'commercial_vehicle'
              ) {
                const vehicleResult = result as CommercialVehicleOption;
                const selectedVehicleOption =
                  selectedOption as CommercialVehicleOption;
                return (
                  <CommercialVehicleEntry
                    entry={vehicleResult}
                    selected={selectedVehicleOption?.vin === vehicleResult.vin}
                    key={vehicleResult.vin}
                    onClick={() => {
                      updateValue(step_component.field, vehicleResult);
                    }}
                  />
                );
              }

              if (step_component.extended_mode_format === 'commercial_driver') {
                const key = step_component.options.some(o => o.employeeId)
                  ? 'employeeId'
                  : 'contactId';
                const driverResult = result as CommercialDriverOption;
                const selectedDriverOption =
                  selectedOption as CommercialDriverOption;

                return (
                  <CommercialDriverEntry
                    entry={driverResult}
                    selected={selectedDriverOption?.[key] === driverResult[key]}
                    key={driverResult[key]}
                    onClick={() => {
                      updateValue(step_component.field, {
                        ...driverResult,
                        seat: 'DRIVER',
                      });
                    }}
                  />
                );
              }

              return <div>{JSON.stringify(result)}</div>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtendedSelectOrCreate;
