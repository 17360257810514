import classNames from 'classnames';

import { CommercialAutoVehicleThumbnailType } from '@assured/shared-types/CommercialAutoVehicle';

import tractor from '../../images/commercial_auto_vehicles/01 Tractor.png';
import dumpTruck from '../../images/commercial_auto_vehicles/02 Dump Truck.png';
import flatbedTruck from '../../images/commercial_auto_vehicles/03 Flatbed Truck.png';
import pickup from '../../images/commercial_auto_vehicles/05, 5A, 5B, 55, 58, 61 Pickup.png';
import boxVan from '../../images/commercial_auto_vehicles/06 Mobile Medical Lab and Box Van, Mobile Vet Labs.png';
import streetSweeper from '../../images/commercial_auto_vehicles/08 Street Sweeper.png';
import tankTruck from '../../images/commercial_auto_vehicles/08 Tank truck, Steet Sweeper.png';
import miniVan from '../../images/commercial_auto_vehicles/09 Mini Van.png';
import tankTrailer from '../../images/commercial_auto_vehicles/12 Tank Trailer.png';
import flatbedTrailer from '../../images/commercial_auto_vehicles/13 Flatbed Trailer.png';
import livestockTrailer from '../../images/commercial_auto_vehicles/15 Livestock trailer.png';
import refrigeratedDryFreightTrailer from '../../images/commercial_auto_vehicles/17 Refrigerated Dry Freight Trailer.png';
import horseTrailer from '../../images/commercial_auto_vehicles/18 Horse Trailer 1 - 2 stalls.png';
import lowboyTrailer from '../../images/commercial_auto_vehicles/19 Lowboy Trailer.png';
import bulkCommodityTrailer from '../../images/commercial_auto_vehicles/21 Bulk Commodity Trailer.png';
import bottomDumpGrainTrailer from '../../images/commercial_auto_vehicles/23 Bottom Dump Grain Trailer.png';
import autoHaulingTrailer from '../../images/commercial_auto_vehicles/26 Auto Hauling Trailer.png';
import sportAuto from '../../images/commercial_auto_vehicles/27 Sport Auto.png';
import largeHorseTrailer from '../../images/commercial_auto_vehicles/28 Large Horse Trailer.png';
import utilityTrailer from '../../images/commercial_auto_vehicles/28 Utility Trailer.png';
import luxuryAuto from '../../images/commercial_auto_vehicles/29 Luxury Auto.png';
import privatePassengerAuto from '../../images/commercial_auto_vehicles/30 Private Passenger Auto.png';
import motorHome from '../../images/commercial_auto_vehicles/31 Motor Home.png';
import utilityVehicle from '../../images/commercial_auto_vehicles/32 Utility Vehicle.png';
import stepVan from '../../images/commercial_auto_vehicles/33 Step Van.png';
import luxurySUV from '../../images/commercial_auto_vehicles/34 Luxury SUV.png';
import deliveryVan from '../../images/commercial_auto_vehicles/35 Delivery Van.png';
import limousine from '../../images/commercial_auto_vehicles/37, 39, 49 Limousine.png';
import hearse from '../../images/commercial_auto_vehicles/38 Hearse.png';
import straightTruck from '../../images/commercial_auto_vehicles/40 Straight Truck.png';
import straightTruckGreater from '../../images/commercial_auto_vehicles/42 Straight Truck -  GVW_ Greater than 26,000.png';
import iceCreamTruck from '../../images/commercial_auto_vehicles/46 Ice Cream Truck.png';
import travelTrailer from '../../images/commercial_auto_vehicles/48 Travel Trailer.png';
import towTruckSingleAxle from '../../images/commercial_auto_vehicles/4A, 4B Tow Truck Single Axle.png';
import cementMixer from '../../images/commercial_auto_vehicles/50 Cement Mixer.png';
import towTruckTwoAxle from '../../images/commercial_auto_vehicles/54 Tow Truck - Two axle.png';
import cateringTruck from '../../images/commercial_auto_vehicles/56 Catering Truck.png';
import garbageTruck from '../../images/commercial_auto_vehicles/65 Garbage Truck.png';
import wheelchairBus from '../../images/commercial_auto_vehicles/6D Wheelchair bus.png';
import frontLoader from '../../images/commercial_auto_vehicles/74 Front Loader (Garbage truck).png';
import bucketTruck from '../../images/commercial_auto_vehicles/77 Bucket Truck.png';
import gooseneckTrailer from '../../images/commercial_auto_vehicles/82 Gooseneck Trailer.png';
import pumpTruck from '../../images/commercial_auto_vehicles/87 Pump Truck.png';
import bus9To15Passengers from '../../images/commercial_auto_vehicles/91 Bus (9-15 passengers).png';
import bus16To60Passengers from '../../images/commercial_auto_vehicles/92 Bus (16-60 passengers).png';
import bus61PlusPassenger from '../../images/commercial_auto_vehicles/93 Bus (61+ passenger).png';
import boomCraneTruck from '../../images/commercial_auto_vehicles/95, 96, 97 Boom Crane truck.png';

const imageMap: Record<CommercialAutoVehicleThumbnailType, string> = {
  tractor,
  dumpTruck,
  flatbedTruck,
  pickup,
  boxVan,
  streetSweeper,
  tankTruck,
  miniVan,
  tankTrailer,
  flatbedTrailer,
  livestockTrailer,
  refrigeratedDryFreightTrailer,
  horseTrailer,
  lowboyTrailer,
  bulkCommodityTrailer,
  bottomDumpGrainTrailer,
  autoHaulingTrailer,
  sportAuto,
  largeHorseTrailer,
  utilityTrailer,
  luxuryAuto,
  privatePassengerAuto,
  motorHome,
  utilityVehicle,
  stepVan,
  luxurySUV,
  deliveryVan,
  limousine,
  hearse,
  straightTruck,
  straightTruckGreater,
  iceCreamTruck,
  travelTrailer,
  towTruckSingleAxle,
  cementMixer,
  towTruckTwoAxle,
  cateringTruck,
  garbageTruck,
  wheelchairBus,
  frontLoader,
  bucketTruck,
  gooseneckTrailer,
  pumpTruck,
  bus9To15Passengers,
  bus16To60Passengers,
  bus61PlusPassenger,
  boomCraneTruck,
};

export const CommercialAutoVehicleThumbnail = ({
  type,
  className,
}: {
  type: CommercialAutoVehicleThumbnailType;
  className?: string;
}) => {
  const src = imageMap[type];

  if (!src) {
    return null;
  }

  return (
    <div
      className={classNames(
        'h-12 overflow-hidden flex items-center justify-center',
        className,
      )}
    >
      <img
        src={src}
        alt={`${type.replaceAll('_', ' ')}`}
        className="max-h-full max-w-full object-contain mix-blend-multiply"
      />
    </div>
  );
};
