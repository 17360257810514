import classNames from 'classnames';
import React from 'react';

const LOGOS: Record<string, any> = {
  aaa: require('../images/logos/aaa.png'),
  assured: require('../images/logos/assured.png'),
  assuredHorizontal: require('../images/logos/assured-horizontal.png'),
  amfam: require('../images/logos/amfam.png'),
  csaa: require('../images/logos/csaa.svg'),
  homesite: require('../images/logos/homesite.png'),
  mainstreetamerica: require('../images/logos/mainstreetamerica.jpg'),
  thegeneral: require('../images/logos/thegeneral.png'),
  allianz: require('../images/logos/allianz.svg'),
  geico: require('../images/logos/geico.png'),
  geicoLarge: require('../images/logos/geico-big.jpg'),
  liberty: require('../images/logos/liberty.png'),
  libertymutual: require('../images/logos/liberty.png'),
  mercury: require('../images/logos/mercury.svg'),
  mercuryLarge: require('../images/logos/mercury-big.svg'),
  hanover: require('../images/logos/hanover.svg'),
  hanoverLarge: require('../images/logos/hanover-big.svg'),
  statefarm: require('../images/logos/statefarm.svg'),
  cinfin: require('../images/logos/cinfin.png'),
  cinfinLarge: require('../images/logos/cinfin-big.png'),
  kemper: require('../images/logos/kemper.png'),
  tesla: require('../images/logos/tesla.svg'),
  teslaCar: require('../images/logos/tesla-car.png'),
  teslaInsuranceText: require('../images/logos/tesla-insurance.svg'),
  safeauto: require('../images/logos/safeauto-round.png'),
  safeauto2x: require('../images/logos/safeauto-round@2x.png'),
  safeauto3x: require('../images/logos/safeauto-round@3x.png'),
  allstate: require('../images/logos/allstate.svg'),
  allstateLarge: require('../images/logos/allstate-big.svg'),
  txfb: require('../images/logos/txfb.png'),
  txfbLarge: require('../images/logos/txfb-big.png'),
  utica: require('../images/logos/utica.png'),
  uticaIcon: require('../images/logos/utica-icon.png'),
  americanmodern: require('../images/logos/americanmodern.png'),
  americanmodernLarge: require('../images/logos/americanmodern-big.png'),
  plymouthrock: require('../images/logos/plymouthrock.png'),
  travelers: require('../images/logos/travelers-insurance.png'),
  shelter: require('../images/logos/shelter-insurance-logo.png'),
  shelterTransparent: require('../images/logos/shelter-transparent-logo.png'),
  selective: require('../images/logos/selective-insurance-logo.svg'),
  selectiveWhite: require('../images/logos/selective-insurance-logo-white.svg'),
  usaa: require('../images/logos/usaa.svg'),
  crawford: require('../images/logos/crawford-logo.png'),
  countryfinancial: require('../images/logos/countryfinancial.svg'),
  autoowners: require('../images/logos/autoowners.png'),
  farmers: require('../images/logos/farmers.svg'),
  progressive: require('../images/logos/progressive.svg'),
  chubb: require('../images/logos/chubb.png'),
  westfield: require('../images/logos/westfield.svg'),
  nycm: require('../images/logos/nycm.svg'),
  donegal: require('../images/logos/donegal.svg'),
  tokiomarineamerica: require('../images/logos/tokiomarineamerica.png'),
  mapfre: require('../images/logos/mapfre.svg'),
  westbend: require('../images/logos/westbend.png'),
  americannational: require('../images/logos/americannational.png'),
  nationalgeneral: require('../images/logos/nationalgeneral.png'),
  nationalgeneralLarge: require('../images/logos/nationalgeneral-big.png'),
  hiroad: require('../images/logos/hiroad.svg'),
  autoclubgroup: require('../images/logos/autoclubgroup.svg'),
  vtm: require('../images/logos/vtmutual.svg'),
  thegeneral: require('../images/logos/thegeneral.svg'),
  meemic: require('../images/logos/meemic.png'),
  amtrust: require('../images/logos/amtrust.png'),
  thehartford: require('../images/logos/thehartford.png'),
  safety: require('../images/logos/safety.png'),
  sentry: require('../images/logos/sentry.png'),
  norfolk: require('../images/logos/norfolk.svg'),
};

const getSmallStyle = (tenant: string) =>
  classNames(
    tenant === 'allianz'
      ? 'px-8'
      : tenant === 'hanover' || tenant === 'kemper'
      ? 'px-6'
      : 'px-2',
    tenant === 'hanover' ? 'py-2' : '',
    tenant === 'crawford' ? 'px-4 md:px-0' : '',
    tenant === 'chubb' ? 'max-w-[150px]' : '',
    tenant === 'progressive' ? 'w-full max-w-[300px]' : '',
    tenant === 'autoclubgroup' ? 'min-h-[60px]' : '',
  );

const getLargeStyle = (tenant: string) =>
  tenant === 'allianz' ||
  tenant === 'kemper' ||
  tenant === 'progressive' ||
  tenant === 'chubb'
    ? 'px-10'
    : '';

interface TenantLogoProps {
  tenant: string;
  size: string;
  horizontal?: boolean;
  [key: string]: any;
}
const TenantLogo: React.FC<TenantLogoProps> = ({
  tenant,
  displayName = 'Insurance Provider',
  size,
  horizontal,
  darkMode,
  ...rest
}) => {
  const className = classNames(
    rest.className,
    size === 'small' && getSmallStyle(tenant),
    size === 'large' && getLargeStyle(tenant),
  );
  let maxHeight = 60,
    minHeight;

  if (tenant === 'geico' && size === 'large') {
    maxHeight = 180;
    tenant = 'geicoLarge';
  }

  if (tenant === 'mercury' && size === 'large') {
    maxHeight = 140;
    tenant = 'mercuryLarge';
  }

  if (tenant === 'liberty' && size === 'large') {
    maxHeight = 120;
  }

  if (tenant === 'hanover' && size === 'large') {
    maxHeight = 70;
    tenant = 'hanoverLarge';
  }

  if (tenant === 'cinfin' && size === 'large') {
    maxHeight = 110;
    tenant = 'cinfinLarge';
  }

  if (tenant === 'safeauto' && size === 'large') {
    maxHeight = 130;
    rest.srcSet = `${LOGOS.safeauto2x} 2x, ${LOGOS.safeauto3x} 3x`;
  }

  if (tenant === 'shelter') {
    minHeight = 80;
    if (darkMode) {
      tenant = 'shelterTransparent';
    }
  }

  if (tenant === 'allstate' && size === 'large') {
    maxHeight = 170;
    minHeight = 150;
    tenant = 'allstateLarge';
  }

  if (tenant == 'csaa') {
    tenant = 'aaa';
  }

  if (tenant === 'txfb' && size === 'large') {
    maxHeight = 160;
    tenant = 'txfbLarge';
  }

  if (tenant === 'utica' && size !== 'large') {
    tenant = 'uticaIcon';
  }

  if (tenant === 'assured' && horizontal) {
    tenant = 'assuredHorizontal';
  }

  if (tenant === 'americanmodern' && size === 'large') {
    maxHeight = 160;
    tenant = 'americanmodernLarge';
  }

  if (tenant === 'usaa') {
    maxHeight = 120;
  }

  if (tenant === 'countryfinancial') {
    minHeight = 70;
  }

  if (tenant === 'farmers' && size === 'large') {
    maxHeight = 120;
  }

  if (tenant === 'americannational' && size === 'large') {
    maxHeight = 120;
  }

  if (tenant === 'nationalgeneral' && size === 'large') {
    maxHeight = 120;
    tenant = 'nationalgeneralLarge';
  }

  if (tenant === 'autoclubgroup' && size === 'large') {
    maxHeight = 100;
  }

  if (tenant === 'vtmutual' && size === 'large') {
    maxHeight = 120;
  }

  if (tenant === 'thehartford' && size === 'large') {
    maxHeight = 100;
  }

  if (tenant === 'selective' && darkMode) {
    tenant = 'selectiveWhite';
  }

  if (tenant === 'selectiveDark') {
    tenant = 'selective';
  }

  if (tenant === 'tesla' && size === 'large') {
    return (
      <div {...rest} className={className}>
        <img
          src={LOGOS['tesla']}
          className="block mx-auto"
          style={{ maxHeight }}
          alt="Tesla Insurance Logo (Small)"
        />
        <img
          src={LOGOS['teslaInsuranceText']}
          className="mt-4 block mx-auto"
          alt="Tesla Insurance Logo (Medium)"
        />
        <img
          src={LOGOS['teslaCar']}
          className="block mt-2 mx-auto"
          style={{ maxWidth: 400, width: '90%' }}
          alt="Tesla Insurance Logo (Large)"
        />
      </div>
    );
  }

  if (LOGOS[tenant]) {
    return (
      <img
        src={LOGOS[tenant]}
        style={{ maxHeight, minHeight }}
        {...rest}
        className={className}
        alt={`${displayName} Logo`}
      />
    );
  }

  return (
    <div {...rest} className={className}>
      <span className="text-2xl font-bold text-blue-600">{tenant}</span>
    </div>
  );
};

export default TenantLogo;
