import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

/**
 * Evaluates an A/B test, returns the variant and records an analytics event to track the variant
 * for the specific user.
 *
 * This code originated from the abTestingDisplayVariant function in apps/backend/src/services/ABTesting.ts
 * This is doing basically the same thing except using the frontend LaunchDarkly/analytics client instead.
 */
export const useAbTestingVariant = (
  abTest: string,
  defaultVariant?: boolean,
): boolean => {
  const ldClient = useLDClient();
  const abTestVariant = ldClient?.variation(
    abTest,
    defaultVariant ?? undefined,
  ) as boolean;

  useEffect(() => {
    window.analytics.track('$experiment_started', {
      'Experiment name': abTest,
      'Variant name': abTestVariant,
    });

    window.analytics.identify({
      traits: {
        [`Experiment: ${abTest}`]: abTestVariant,
      },
    });
  }, [abTest, abTestVariant]);

  return abTestVariant;
};
