import { SelectDropdown, SelectDropdownProps } from '../SelectDropdown';

import { STATES_OPTIONS } from './options';

export interface SelectStateDropdownProps
  extends Omit<SelectDropdownProps, 'options'> {
  hideLabel?: boolean;
}

const hiddenLabelProps = {
  labelStr: '' /* hide the label that shows by default */,
};

export const SelectStateDropdown: React.FC<SelectStateDropdownProps> = ({
  value,
  onChange,
  autoFocus,
  className,
  classNames,
  placeholder,
  hideLabel,
  styles,
  ...props
}) => {
  const labelProps = hideLabel
    ? hiddenLabelProps
    : {
        ...props.labelProps,
        labelStr: props.labelProps?.labelStr ?? 'State',
      };

  return (
    <SelectDropdown
      className={className}
      classNames={classNames}
      styles={styles}
      labelProps={labelProps}
      placeholder={placeholder ?? 'Choose state'}
      value={value}
      options={STATES_OPTIONS}
      onChange={onChange}
      autoFocus={autoFocus}
      menuPlacement="bottom"
      menuPosition="absolute"
      minQueryLength={1}
      shouldSortByScore
    />
  );
};
